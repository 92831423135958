import { configureStore } from "@reduxjs/toolkit";

import documentsSlice from "./documents-slice";
import taxonomySlice from "./taxonomy-slice";

export const store = configureStore({
  reducer: {
    taxonomySlice,
    documentsSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type AppDispatch = typeof store.dispatch;
