import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchTaxonomyEntitiesAPI, uploadTaxonomyFileAPI } from "api/taxonomy";
import { InterceptedResponse, Status } from "models/shared";
import {
  FetchTaxonomyEntitiesAPIRequest,
  TaxonomyEntity,
  UploadTaxonomyFileAPIRequest,
} from "models/taxonomy.models";

interface TaxonomyState {
  isFetchingTaxonomyEntries: Status;
  isUploadingTaxonomyFile: Status;
  taxonomyEntities: TaxonomyEntity[];
}

const initialState = {
  isFetchingTaxonomyEntries: "idle",
  isUploadingTaxonomyFile: "idle",
  taxonomyEntities: [],
} as TaxonomyState;

export const fetchTaxonomyEntities = createAsyncThunk(
  "taxonomy/fetchTaxonomyEntities",
  async (request: FetchTaxonomyEntitiesAPIRequest) => {
    const response = await fetchTaxonomyEntitiesAPI(request);
    return response;
  }
);

export const uploadTaxonomyFile = createAsyncThunk(
  "taxonomy/uploadTaxonomyFile",
  async (request: UploadTaxonomyFileAPIRequest) => {
    const response = await uploadTaxonomyFileAPI(request);
    return response;
  }
);

export const taxonomySlice = createSlice({
  name: "taxonomy",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTaxonomyEntities.pending, (state: TaxonomyState) => {
        state.isFetchingTaxonomyEntries = "loading";
      })
      .addCase(
        fetchTaxonomyEntities.fulfilled,
        (state: TaxonomyState, { payload }) => {
          state.isFetchingTaxonomyEntries = "succeeded";
          state.taxonomyEntities = (
            payload as InterceptedResponse
          )?.data?.entities;
        }
      )
      .addCase(fetchTaxonomyEntities.rejected, (state: TaxonomyState) => {
        state.isFetchingTaxonomyEntries = "failed";
      })
      .addCase(uploadTaxonomyFile.pending, (state: TaxonomyState) => {
        state.isUploadingTaxonomyFile = "loading";
      })
      .addCase(uploadTaxonomyFile.fulfilled, (state: TaxonomyState) => {
        state.isUploadingTaxonomyFile = "succeeded";
      })
      .addCase(uploadTaxonomyFile.rejected, (state: TaxonomyState) => {
        state.isUploadingTaxonomyFile = "failed";
      });
  },
});

export default taxonomySlice.reducer;
