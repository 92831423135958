import "./App.scss";

import { Spin } from "antd";
import { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router";

import BaseLayout from "layout/BaseLayout";

const Documents = lazy(() => import("pages/Documents"));
const Taxonomy = lazy(() => import("pages/Taxonomy"));
const DocumentDetails = lazy(() => import("pages/DocumentDetails"));

const App = () => (
  <Suspense fallback={<Spin />}>
    <Routes>
      <Route path="/" element={<Navigate to="/documents" />} />
      <Route
        path="/documents"
        element={<BaseLayout component={<Documents />} />}
      />
      <Route
        path="/documents/:id"
        element={<BaseLayout component={<DocumentDetails />} />}
      />
      <Route
        path="/taxonomy"
        element={<BaseLayout component={<Taxonomy />} />}
      />
    </Routes>
  </Suspense>
);

export default App;
