import { ListingOptions } from "models/shared";

export const baseUrl = process.env.REACT_APP_GRAPH_GEN_URL;

export const DEFAULT_ERROR_MSG =
  "Something went wrong, Please try again later.";

export const ENTITY_TYPE = "Product";
export const GENERIC_SIZE_OPTIONS = [10, 25, 50, 100];

export const DEFAULT_TABLE_PAGINATION: ListingOptions = {
  offset: 0,
  limit: 10,
};
