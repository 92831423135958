import "./BaseLayout.scss";

import { Flex, Layout, Menu, Typography } from "antd";
import { ReactNode } from "react";
import { Link, useLocation } from "react-router";


type Props = {
  component: ReactNode;
};

export enum NavItems {
  Documents = "/documents",
  Taxonomy = "/taxonomy",
}

const { Content, Header, Sider } = Layout;
const { Title } = Typography;


const navigationItems = [
  {
    label: "Documents",
    key: NavItems.Documents,
  },
  {
    label: "Taxonomy",
    key: NavItems.Taxonomy,
  },
];


const BaseLayout = ({ component }: Props) => {
  const { pathname } = useLocation();

  return (
    <Layout className="app-layout">
      <Sider>
        <Flex justify="center" className="logo-wrapper">
          <img src="/assets/agolo-logo.svg" alt="company-logo" />
        </Flex>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[pathname || NavItems.Documents]}
          className="nav-menu"
        >
          {navigationItems.map((item) => (
            <Menu.Item key={item?.key}>
              <Link to={item.key}>{item.label}</Link>
            </Menu.Item>
          ))}
        </Menu>
      </Sider>

      <Layout>
        <Header>
          <Title level={3} className="app-title">
            Admin Dashboard
          </Title>
        </Header>

        <Content className="app-content-wrapper">{component}</Content>
      </Layout>
    </Layout>
  )
};

export default BaseLayout;
