import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  fetchDocumentAPI,
  fetchDocumentsListAPI,
  uploadDocumentsAPI,
} from "api/documents";
import { IDocument, ListDocumentsResponse } from "models/documents.model";
import { InterceptedResponse, ListingOptions, Status } from "models/shared";

interface DocumentsState {
  isFetchingDocumentsList: Status;
  isFetchingDocument: Status;
  isUploadingDocuments: Status;
  documentsList: ListDocumentsResponse;
  selectedDocument: IDocument;
}

const initialState = {
  isFetchingDocumentsList: "idle",
  isFetchingDocument: "idle",
  isUploadingDocuments: "idle",
  documentsList: {},
} as DocumentsState;

export const fetchDocumentsList = createAsyncThunk(
  "documents/fetchDocumentsList",
  async (request: ListingOptions) => {
    const response = await fetchDocumentsListAPI(request);
    return response;
  }
);

export const fetchDocument = createAsyncThunk(
  "documents/fetchDocument",
  async (id: string) => {
    const response = await fetchDocumentAPI(id);
    return response;
  }
);

export const uploadDocuments = createAsyncThunk(
  "documents/uploadDocuments",
  async (formData: FormData) => {
    const response = await uploadDocumentsAPI(formData);
    return response;
  }
);

export const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchDocumentsList.pending, (state: DocumentsState) => {
        state.isFetchingDocumentsList = "loading";
      })
      .addCase(
        fetchDocumentsList.fulfilled,
        (state: DocumentsState, { payload }) => {
          state.isFetchingDocumentsList = "succeeded";
          state.documentsList = (payload as InterceptedResponse).data;
        }
      )
      .addCase(fetchDocumentsList.rejected, (state: DocumentsState) => {
        state.isFetchingDocumentsList = "failed";
      })
      .addCase(fetchDocument.pending, (state: DocumentsState) => {
        state.isFetchingDocument = "loading";
      })
      .addCase(
        fetchDocument.fulfilled,
        (state: DocumentsState, { payload }) => {
          state.isFetchingDocument = "succeeded";
          state.selectedDocument = (payload as InterceptedResponse).data;
        }
      )
      .addCase(fetchDocument.rejected, (state: DocumentsState) => {
        state.isFetchingDocument = "failed";
      })
      .addCase(uploadDocuments.pending, (state: DocumentsState) => {
        state.isUploadingDocuments = "loading";
      })
      .addCase(uploadDocuments.fulfilled, (state: DocumentsState) => {
        state.isUploadingDocuments = "succeeded";
      })
      .addCase(uploadDocuments.rejected, (state: DocumentsState) => {
        state.isUploadingDocuments = "failed";
      });
  },
});

export default documentsSlice.reducer;
