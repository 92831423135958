import { ListingOptions } from "models/shared";

import { axiosConfig } from "./axios";

const baseUrl = "documents";

export const fetchDocumentsListAPI = async (request: ListingOptions) => {
  const { offset, limit } = request;
  const url = `${baseUrl}?offset=${offset}&limit=${limit}`;
  const response = await axiosConfig("GET", url);
  return response;
};

export const fetchDocumentAPI = async (id: string) => {
  const url = `document/${id}`;
  const response = await axiosConfig("GET", url);
  return response;
};

export const uploadDocumentsAPI = async (formData: FormData) => {
  const url = `${baseUrl}`;
  const response = await axiosConfig("POST", url, formData, true);
  return response;
};
