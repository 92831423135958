import { ThemeConfig } from "antd";

import { lightPalette } from "./light-palette";

export const lightTheme: ThemeConfig = {
  token: {
    borderRadius: 0,
    fontFamily: "Oxygen",
  },
  components: {
    Tag: {
      borderRadiusSM: 5,
    },
  },
};

const createCssVar = (items: any, prefix = "-"): string[] =>
  Object.entries(items).flatMap(([key, value]) => {
    const varName = `${prefix}-${key}`;
    if (typeof value === "object") return createCssVar(value, varName);
    return `${varName}:${value}`;
  });

const createCssVars = (themeColors: any) => createCssVar(themeColors).join(";");
const sheet = document.styleSheets[0];
sheet.insertRule(`:root{${createCssVars(lightPalette)}}`, 1);

export {};
